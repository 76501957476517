const IntellectualCard = ({ image, name, className }) => {
	return (
		<div
			className={`bg-blue-dark w-8/17 md:w-4/17 h-36 md:h-48 lg:h-64 mt-4 md:mt-8 shadow-lg rounded-lg flex justify-center items-center transform transition duration-500 hover:scale-110 ${className}`}
		>
			<div className="h-3/4 w-4/5 flex flex-col justify-between">
				<img src={image} alt="img" className="m-auto w-2/6" />
				<p className="text-white text-center tracking-wide font-medium text-xs lg:text-base xl:text-lg font-serif">
					{name}
				</p>
			</div>
		</div>
	);
};

export { IntellectualCard };
