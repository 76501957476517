import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

const NavItem = ({ isActive, item }) => {
  return (
    <li
      className={classNames(
        "tracking-wide xl:pb-2 md:pb-1 float-left text-white font-serif xl:font-medium md:font-normal xl:text-base lg:text-sm md:text-xs pr-8",
        {
          "border-b-4": isActive,
          "border-blue-light": isActive,
        }
      )}
    >
      <Link className="no-underline" to={item.url}>
        {item.name}
      </Link>
    </li>
  );
};

export default NavItem;
