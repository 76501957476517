import image4 from "../assets/blog/blog4.png";
import image5 from "../assets/blog/blog5.png";
import image6 from "../assets/blog/blog6.png";
import image7 from "../assets/blog/blog7.png";
import image8 from "../assets/blog/blog8.png";

const BlogData = [
  {
    picture: image4,
    title: "B.B Dan-Habu & Co opens a new branch in Abuja",
    date: "August 20, 2018",
    author: "Admin",
    body:
      "We are pleased to officially announce the expansion of our law firm to Abuja. Our new branch is located at No 1 Sirasso close, Wuse Zone 7, Abuja. This expansion will enable us respond effectively to the growing needs of our Clients. You can expect from this new branch the same high quality service you are used to receiving from us. Our Head of Chambers (Abuja) Mr. Dennis Gapsiso will be happy to provide further information as required.",
    index: 0,
    span: false,
  },
  {
    picture: image5,
    title:
      "Emerging Technology: Nokia expresses its commitment to supporting MNOs in exploring the potential of 5G in Nigeria",
    date: "August 20, 2018",
    author: "Admin",
    body:
      "We are pleased to officially announce the expansion of our law firm to Abuja. Our new branch is located at No 1 Sirasso close, Wuse Zone 7, Abuja. This expansion will enable us respond effectively to the growing needs of our Clients. You can expect from this new branch the same high quality service you are used to receiving from us. Our Head of Chambers (Abuja) Mr. Dennis Gapsiso will be happy to provide further information as required.",
    index: 1,
    span: false,
  },
  {
    picture: image6,
    title: "President signs Nine Bills into Law",
    date: "August 20, 2018",
    author: "Admin",
    body:
      "We are pleased to officially announce the expansion of our law firm to Abuja. Our new branch is located at No 1 Sirasso close, Wuse Zone 7, Abuja. This expansion will enable us respond effectively to the growing needs of our Clients. You can expect from this new branch the same high quality service you are used to receiving from us. Our Head of Chambers (Abuja) Mr. Dennis Gapsiso will be happy to provide further information as required.",
    index: 2,
    span: false,
  },
  {
    picture: image7,
    title: "Constitutional Law: The Rule of Law and the Independence of the Judiciary",
    date: "August 20, 2018",
    author: "Admin",
    body:
      "We are pleased to officially announce the expansion of our law firm to Abuja. Our new branch is located at No 1 Sirasso close, Wuse Zone 7, Abuja. This expansion will enable us respond effectively to the growing needs of our Clients. You can expect from this new branch the same high quality service you are used to receiving from us. Our Head of Chambers (Abuja) Mr. Dennis Gapsiso will be happy to provide further information as required.",
    index: 3,
    span: true,
  },
  {
    picture: image8,
    title: "Nigeria gets elected to the International Telecommunications Union (ITU)",
    date: "August 20, 2018",
    author: "Admin",
    body:
      "We are pleased to officially announce the expansion of our law firm to Abuja. Our new branch is located at No 1 Sirasso close, Wuse Zone 7, Abuja. This expansion will enable us respond effectively to the growing needs of our Clients. You can expect from this new branch the same high quality service you are used to receiving from us. Our Head of Chambers (Abuja) Mr. Dennis Gapsiso will be happy to provide further information as required.",
    index: 4,
    span: false,
  },
];

const categories = [
  {
    name: "General",
    index: 1,
    amount: 2,
  },
  {
    name: "Tech",
    index: 2,
    amount: 7,
  },
  {
    name: "Uncategorized",
    index: 3,
    amount: 2,
  },
  {
    name: "Video",
    index: 4,
    amount: 4,
  },
  {
    name: "World",
    index: 5,
    amount: 5,
  },
];
export { BlogData, categories };
