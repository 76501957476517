import { Link } from "react-scroll";
import Typewriter from "typewriter-effect";
import SectionIntro from "../templates/SectionIntro";
import discover from "../assets/icons/discover.svg";
import homeImg from "../assets/landing/homeNew.png";
const HomeSlider = () => {
  return (
    <div
      className="h-auto lg:h-screen text-white flex flex-col justify-center relative pt-36 lg:pt-20"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.6) , rgba(0,0,0,0.6) ), url(${homeImg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="m-auto w-10/12 lg:w-full xl:px-16 lg:px-14 flex items-center justify-between relative">
        <div className="container">
          <SectionIntro title="B.B. Dan-Habu & Co" />
          <div
            className="xl:text-7xl md:w-2/5 text-6xl xl:mb-16 lg:mb-14 home-carousel font-serif"
            style={{ lineHeight: "90px", minHeight: "300px" }}
          >
            <Typewriter
              onInit={(typewriter) => {
                typewriter.pauseFor(1000).typeString("Integrity.").start();
              }}
            />
            <Typewriter
              onInit={(typewriter) => {
                typewriter.pauseFor(2500).typeString("Experience.").start();
              }}
            />
            <Typewriter
              onInit={(typewriter) => {
                typewriter.pauseFor(5000).typeString("Results.").start();
              }}
            />
          </div>
          <Link
            to="content"
            spy={true}
            smooth={true}
            offset={0}
            className="flex items-center cursor-pointer"
          >
            <img className="w-12" src={discover} alt="discover more" />
            <p className="font-bold ml-5 uppercase text-sm">Discover More</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export { HomeSlider };
