import React, { useContext, useEffect } from "react";

import SectionIntro from "../../templates/SectionIntro";
import ExpertiseCard from "../../molecules/ExpertiseCard";
import expertiseItems from "../../data/expertiseLandingItems";
import { SectionWrapper } from "../../layout/SectionWrapper";
import { Context } from "../../store/reducer";
import { setState } from "../../store/actionsCreators";
import epertiseImage from "../../assets/expertise/expertiseBg.png";

const Expertise = () => {
  const { dispatch } = useContext(Context);

  useEffect(() => {
    dispatch(setState(epertiseImage, "Expertise"));
  }, [dispatch]);

  return (
    <div className="bg-white text-blue-darkBlue">
      <SectionWrapper>
        <div className="">
          <SectionIntro title="expertise" />
          <p
            className="text-4xl font-bold mb-8"
            data-aos="fade-down"
            data-aos-duration="800"
            data-aos-easing="linear"
            data-aos-once="true"
          >
            Our Expertise
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid lg:grid-cols-2 mb-16">
          {expertiseItems.map((item) => {
            return <ExpertiseCard key={item.id} {...item} id={item.id} />;
          })}
        </div>
      </SectionWrapper>
    </div>
  );
};

export { Expertise };
