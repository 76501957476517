import business from "../assets/intellectual/business.svg";
import assets from "../assets/intellectual/assets.svg";
import building from "../assets/intellectual/building.svg";
import file from "../assets/intellectual/file.svg";
import page from "../assets/intellectual/page.svg";
import rating from "../assets/intellectual/rating.svg";
import stats from "../assets/intellectual/stats.svg";
import support from "../assets/intellectual/support.svg";
import { IntellectualCard } from "../organisms";

const Cards = () => {
	return (
		<section className="flex justify-between flex-wrap">
			<IntellectualCard image={business} name="IP valuation and appraisal" />
			<IntellectualCard image={assets} name="IP asset based lending" />
			<IntellectualCard image={support} name="M & A support & due diligence" />
			<IntellectualCard image={page} name="IP competitive intelligence" />
			<IntellectualCard image={rating} name="Determination of royalty rates" className="p-2" />
			<IntellectualCard image={file} name="Licensing strategies" />
			<IntellectualCard image={stats} name="Financing strategies for IP based startups" />
			<IntellectualCard image={building} name="Quantifying intellectual property damage claim" />
		</section>
	);
};

export default Cards;
