import React from "react";
import backgroundImage from "../assets/footer/footer.png";
import { CustomLink } from "../atoms";

import { SectionWrapper } from "../layout/SectionWrapper";
import SectionIntro from "../templates/SectionIntro";

const Footer = () => {
  return (
    <footer
      className="bg-local bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <SectionWrapper className="text-white">
        <SectionIntro title="contact us" className="text-white" />
        {/* <HeaderText
					text="Lets provide you proficient, innovative, cost-effective, competent and timely solutions."
					className="text-white font-black"
				/> */}
        <CustomLink
          link="/contact-us"
          text="Let's work together"
          className="mt-1 md:mt-2 lg:mt-16 xl:mt-20 font-medium"
        />
        <hr className="h-5 mt-12 md:mt-16 lg:mt-20 xl:mt-24 text-white mb-4 md:mb-8" />
        <section className="md:flex">
          <div
            className="xl:mr-16 lg:mr-14 md:mr-12"
            data-aos="slide-up"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <p className="text-sm lg:text-base xl:text-lg 2xl:text-xl font-black text-gray">
              Enquiries
            </p>
            <p className="text-base md:text-sm md:block lg:text-lg 2xl:text-xl mt-2.5 md:mt-3 xl:mt-4 font-extrabold">
              01-5820574 | 01-582057
            </p>
            <a
              href="/contact"
              data-hover="/contact"
              className="no-underline text-base md:text-sm font-extrabold lg:text-lg 2xl:text-xl"
            >
              Contact
            </a>
            <br />
            {/* <a
              href="mailto:info@bbdanhabuco.com"
              data-hover="info@bbdanhabuco.com"
              className="no-underline text-base md:text-sm font-extrabold lg:text-lg 2xl:text-xl"
            >
              Support
            </a> */}
          </div>
          <div
            data-aos="slide-up"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
            className="xl:mr-16 lg:mr-14 md:mr-12 my-12 md:my-0"
          >
            <p className="text-sm lg:text-base xl:text-lg 2xl:text-xl font-black text-gray">
              Address
            </p>
            <p className="text-base md:text-sm lg:text-lg 2xl:text-xl mt-4 font-extrabold w-2/3 md:w-full">
              276 Murtala Muhammed way, Yaba
            </p>
          </div>
          <div
            data-aos="slide-up"
            data-aos-duration="1400"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
            className="lg:mr-14 md:mr-12"
          >
            <p className="text-sm lg:text-base xl:text-lg 2xl:text-xl font-black text-gray">
              Follow Us
            </p>
            <div className="flex justify-between font-extrabold w-3/4 md:w-full mt-3 md:mt-0">
              <p className="text-base md:text-sm lg:text-lg 2xl:text-xl xl:mt-4 md:mt-2 xl:mr-4 md:mr-2">
                Facebook
              </p>
              <p className="text-base md:text-sm lg:text-lg 2xl:text-xl xl:mt-4 md:mt-2 xl:mr-4 md:mr-2">
                Twitter
              </p>
              <a href="https://www.linkedin.com/company/b-b-dan-habu-co/">
              <p className="text-base md:text-sm lg:text-lg 2xl:text-xl xl:mt-4 md:mt-2 xl:mr-4 md:mr-2">
                LinkedIn
              </p>
              </a>
            </div>
          </div>
        </section>
        <p
          data-aos="slide-up"
          data-aos-duration="2000"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
          className="text-base md:text-sm lg:text-lg 2xl:text-xl my-14 font-extrabold"
        >
          © {new Date().getFullYear()} BB Dan-Habu & Co.
        </p>
      </SectionWrapper>
    </footer>
  );
};

export { Footer };
