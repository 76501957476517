import { useRef } from "react";
import Slider from "react-slick";
import { ParaLegals, TeamDetails } from "../data/team";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rightArrow from "../assets/icons/right.svg";
import leftArrow from "../assets/icons/left.svg";

const TeamSlider = () => {
  const sliderRef = useRef();

  const handlePrev = (ref) => {
    ref.current.slickPrev();
  };

  const handleNext = (ref) => {
    ref.current.slickNext();
  };
  const settings = {
    dots: false,
    arrows: false,
    autoplay: false,
    infinite: true,
    className: "center",
    centerMode: true,
    slidesToShow: 5,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div
      className="slide-div text-white relative"
      data-aos="fade-down"
      data-aos-duration="1000"
      data-aos-easing="linear"
      data-aos-once="false"
    >
      <img
        src={leftArrow}
        alt="left-arrow"
        onClick={() => handlePrev(sliderRef)}
        className="absolute top-1/3 md:top-1/2 left-4 z-10 cursor-pointer w-16 slider-arrow"
      />
      <img
        src={rightArrow}
        alt="right-arrow"
        onClick={() => handleNext(sliderRef)}
        className="absolute top-1/3 md:top-1/2 right-4 z-10 cursor-pointer w-16 slider-arrow"
      />
      <Slider ref={sliderRef} {...settings}>
        {TeamDetails.map((el, id) => {
          const singleMemberLink = `/team/${el.index}`;
          return (
            <Link to={singleMemberLink} key={id} className="focus:outline-none">
              <div
                className="single-team-div"
                onMouseOver={(e) => (e.currentTarget.style.backgroundImage = `url(${el.picture2})`)}
                onMouseOut={(e) => (e.currentTarget.style.backgroundImage = `url(${el.picture})`)}
                style={{
                  backgroundImage: `url(${el.picture})`,
                  height: "340px",
                  width: "100%",
                  backgroundPosition: "top",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  opacity: "0.3",
                  display: "flex",
                  alignItems: "flex-end",
                  transition: "all .5s",
                }}
              >
                <div
                  className="pt-16 pb-9 px-5 text-center w-full hidden team-text"
                  style={{
                    background: "linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0%",
                  }}
                >
                  <p className="font-sans text-white font-bold text-xl">{el.name}</p>
                  <p className="font-serif mt-2 mb-6 text-gray">{el.designation}</p>
                </div>
              </div>
            </Link>
          );
        })}
      </Slider>
    </div>
  );
};

const ParalegalSlider = () => {
  const sliderRef2 = useRef();

  const handlePrev = (ref) => {
    ref.current.slickPrev();
  };

  const handleNext = (ref) => {
    ref.current.slickNext();
  };
  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    slidesToShow: 4,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          className: "center",
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          className: "center",
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          className: "center",
          centerMode: true,
        },
      },
    ],
  };
  return (
    <div
      className="slide-div text-white relative"
      data-aos="fade-down"
      data-aos-duration="1000"
      data-aos-easing="linear"
      data-aos-once="false"
    >
      <img
        src={leftArrow}
        alt="left-arrow"
        onClick={() => handlePrev(sliderRef2)}
        className="absolute top-1/3 md:top-1/2 left-4 z-10 cursor-pointer w-16 slider-arrow"
      />
      <img
        src={rightArrow}
        alt="right-arrow"
        onClick={() => handleNext(sliderRef2)}
        className="absolute top-1/3 md:top-1/2 right-4 z-10 cursor-pointer w-16 slider-arrow"
      />
      <Slider ref={sliderRef2} {...settings}>
        {ParaLegals.map((el, id) => {
          return (
            <div key={id} className="focus:outline-none">
              <div
                className="single-team-div"
                onMouseOver={(e) => (e.currentTarget.style.backgroundImage = `url(${el.picture2})`)}
                onMouseOut={(e) => (e.currentTarget.style.backgroundImage = `url(${el.picture})`)}
                style={{
                  backgroundImage: `url(${el.picture})`,
                  height: "340px",
                  width: "100%",
                  backgroundPosition: "top",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                  alignItems: "flex-end",
                  opacity: "0.3",
                  transition: "all .5s",
                }}
              >
                <div
                  className="pt-16 pb-9 text-center w-full hidden team-text"
                  style={{
                    background: "linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0%",
                  }}
                >
                  <p className="font-sans text-white font-bold text-2xl">{el.name}</p>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export { TeamSlider, ParalegalSlider };
