import React from "react";
import { ActionTypes } from "./actions";

// Create a Context
export const Context = React.createContext();

export function reducer(state, action) {
  switch (action.type) {
    case ActionTypes.SET_BACKGROUND:
      return {
        ...state,
        headerTitle: action.payload.title,
        background: action.payload.background,
      };
    default:
      return state;
  }
}
