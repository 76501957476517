import React from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import { SectionWrapper } from "../layout/SectionWrapper";
import SectionIntro from "../templates/SectionIntro";
import { HomeSlider } from "../organisms";
import image from "../assets/about/aboutBG.png";

AOS.init();

const Home = () => {
  return (
    <div className="overflow-hidden">
      <HomeSlider />
      <div id="content" className="bg-blue-darkBlue">
        <SectionWrapper>
          <div
            className="flex w-full relative flex-col-reverse lg:flex-row"
            style={{ minHeight: "550px" }}
          >
            <div
              className="lg:absolute relative sm:z-10 lg:top-16"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-easing="ease-in-out"
              data-aos-once="false"
            >
              <div className="bg-white about-div1">
                <SectionIntro title="About B.B. Dan-Habu & Co" />
                <p className="aboutTextBlack tracking-wide xl:text-sm lg:text-sm md:text-xs xl:font-light md:font-extralight font-serif mt-7 md:m-0">
                  B.B. Dan-Habu & Co is a boutique law firm that ranks amongst Nigeria’s leading
                  Commercial Law Firms and serves companies, governments, Nigerian and foreign
                  investors, international firms, and financial institutions. Established in 1986 by
                  Dr. BB. Dan-Habu Esq, the firm has in over two decades handled several complex
                  commercial transactions and developed an enviable portfolio of clients and
                  international experience. Today we are known for our continuous provision of
                  proficient, innovative, cost-effective, competent and timely solutions.
                </p>
              </div>
              <div className="flex justify-center lg:block">
                <Link
                  to="/about"
                  className="mt-8 text-white inline-block no-underline xl:pb-2 md:pb-1 xl:border-b-4 
                border-b-2 uppercase border-blue-light xl:text-sm md:text-xs font-serif 
                transform transition duration-500 hover:scale-105"
                >
                  About Us
                </Link>
              </div>
            </div>
            <div
              className="lg:absolute sm:relative md:right-0 about-div2"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-once="false"
            >
              <img src={image} alt="about us" />
            </div>
          </div>
        </SectionWrapper>
      </div>
    </div>
  );
};

export default Home;
