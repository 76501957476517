import React from "react";

import { SectionWrapper } from "../layout/SectionWrapper";
import SectionIntro from "../templates/SectionIntro";
import { Paragraph } from "../atoms";
import backgroundImage from "../assets/footer/footer.png";

const WhyChoose = () => {
  return (
    <div
      className="bg-local bg-cover bg-center font-serif"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <SectionWrapper>
        <section>
          <SectionIntro title="Why Choose Us" className="text-white" />
          <div className="bg-blue-dark xl:mt-16 lg:mt-14 md:mt-12 mt-8">
            <section className="flex flex-col lg:flex-row justify-between">
              <div
                data-aos="fade-down"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-once="true"
                className="border-t-8 border-blue-lightBlue xl:px-12 px-8 md:px-4 xl:pb-20 pb-12 md:pb-8"
                style={{
                  borderRightStyle: "dashed",
                  borderRightColor: "gray",
                  borderRightWidth: "1px",
                }}
              >
                <p className="font-extrabold text-2xl md:text-base xl:text-xl text-white my-8">
                  Experience
                </p>
                <Paragraph
                  className="leading-relaxed max-w-lg text-white"
                  text=" Established in 1986 by Dr. B.B. Dan-Habu Esq, B.B. Dan-Habu & Co has in three decade
                  developed an enviable portfolio of clients and international experiences."
                />
              </div>

              <div
                data-aos="fade-down"
                data-aos-duration="1200"
                data-aos-easing="ease-in-out"
                data-aos-once="true"
                className="border-t-8 border-blue-light xl:px-12 px-8 md:px-4 xl:pb-20 pb-12 md:pb-8"
                style={{
                  borderRightStyle: "dashed",
                  borderRightColor: "gray",
                  borderRightWidth: "1px",
                }}
              >
                <p className="font-extrabold text-2xl md:text-base xl:text-xl text-white my-8">
                  Innovation
                </p>
                <Paragraph
                  className="leading-relaxed max-w-lg text-white"
                  text=" We remain committed to constantly challenging ourselves to develop new original
                  ways of thinking to even the most complex challenges our clients may face."
                />
              </div>

              <div
                data-aos="fade-down"
                data-aos-duration="1400"
                data-aos-easing="ease-in-out"
                data-aos-once="true"
                className="border-t-8 border-blue-lightBlue xl:px-12 px-8 md:px-4 xl:pb-20 pb-12 md:pb-8"
                style={{
                  borderRightStyle: "dashed",
                  borderRightColor: "gray",
                  borderRightWidth: "1px",
                }}
              >
                <p className="font-extrabold text-2xl md:text-base xl:text-xl text-white my-8">
                  Reputation
                </p>
                <Paragraph
                  className="leading-relaxed max-w-lg text-white"
                  text="We are known for our continuous provision of proficient, innovation,
                  cost-effective, competent and timely solutions."
                />
              </div>

              <div
                data-aos="fade-down"
                data-aos-duration="1600"
                data-aos-easing="ease-in-out"
                data-aos-once="true"
                className="border-t-8 border-blue-light xl:px-12 px-8 md:px-4 xl:pb-20 pb-12 md:pb-8"
              >
                <p className="font-extrabold text-2xl md:text-base xl:text-xl text-white my-8">
                  Track Record
                </p>
                <Paragraph
                  text=" One the years, we have worked alongside some of the world’s most sophisticated
                  business on big ticket transactions."
                  className="leading-relaxed max-w-lg text-white"
                />
              </div>
            </section>
          </div>
        </section>
      </SectionWrapper>
    </div>
  );
};

export { WhyChoose };
