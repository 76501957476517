const Advice = () => {
	return (
		<div>
			<section className="mt-10 xl:mt-28 md:mt-20 font-sans">
				<p className="text-2xl md:text-3xl font-serif font-bold max-w-4xl xl:mb-12 md:mb-10">
					Our experienced team provides clear and structured written and oral advice in:
				</p>
				<p className="text-base font-semibold md:text-lg mt-6">Commercial transactions;</p>
				<p className="text-base font-semibold md:text-lg">Corporate restructuring strategies;</p>
				<p className="text-base font-semibold md:text-lg"> Litigation;</p>
				<p className="text-base font-semibold md:text-lg max-w-xs">
					Governance & regulation and Public policy issues relating to IP
				</p>
			</section>
		</div>
	);
};

export default Advice;
