import React, { useContext, useEffect } from "react";

import { SectionWrapper } from "../../layout/SectionWrapper";
import SectionIntro from "../../templates/SectionIntro";
import { HeaderText, Paragraph } from "../../atoms";
import { WhyChoose, Award } from "../../organisms";
import { Context } from "../../store/reducer";
import { setState } from "../../store/actionsCreators";
import aboutImage from "../../assets/about/aboutHeaderBg.png";
import { CustomLink } from "../../atoms";

const About = () => {
  const { dispatch } = useContext(Context);

  useEffect(() => {
    dispatch(setState(aboutImage, "About"));
  }, [dispatch]);

  return (
    <div className="bg-white ">
      <SectionWrapper>
        <SectionIntro title="About B.B. Dan-Habu & Co" />
        <section>
          <HeaderText
            text="B.B. Dan-Habu & Co is a boutique firm that ranks amongst Nigeria’s leading Commercial
            Law Firms"
            className="text-blue-darkBlue"
          />
          <Paragraph
            text="Our knowledge runs across multiple legal disciplines from corporate and commercial practice to
           Banking Finance Law; International Commercial Transactions; Environmental Law; Shipping, Aviation and Transport
            Law; Capital Market; Expatriate Quota and Residence Permit; Labor, Employment and Pension Law; Tax & Taxation;
             Constitutional & Statutory Interpretation; Litigation & Arbitration; Mergers and Acquisitions; Will, Trust & Probate; Corporate & Commercial Law; General Practice; Real Estate; Emerging Market; Intellectual Property Protection and Commercialization; Restructuring and Insolvency; and Intellectual Property Finance & Valuation."
          />
          <Paragraph
            text="Established in 1986 by Dr. BB. Dan-Habu Esq, the firm has in over two
            decades handled several complex commercial transactions and
            developed an enviable portfolio of clients and international
            experience. Today we are known for our continuous provision of
            proficient, innovative, cost-effective, competent and timely
            solutions."
            className="mt-4"
          />
          <Paragraph
            text="Over the years, we have worked alongside some of the world’s most
            sophisticated businesses on big ticket transactions and we remain
            committed to constantly challenging ourselves to develop new and
            original ways of thinking to even the most complex challenges our
            clients may face."
            className="mt-4"
          />
          <Award />
          <div className="mt-8">
            <CustomLink
              text="View all our expertise"
              link="/expertise"
              className="text-blue-darkBlue"
            />
          </div>
        </section>
      </SectionWrapper>

      <WhyChoose />
    </div>
  );
};

export { About };
