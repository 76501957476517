import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

const MenuNavItem = ({ isActive, item, onCloseClick }) => {
  return (
    <li
      className={classNames(
        "mb-4 xl:pb-6 lg:pb-4 md:pb-2 mt-7 text-white font-sans font-extralight xl:mr-12 md:mr-10",
        {
          "border-b-4 lg:border-b-4 xl:border-b-8": isActive,
          "border-blue-light": isActive,
        }
      )}
    >
      <Link
        className="text-3xl md:text-4xl lg:text-3xl xl:text-6xl"
        to={item.url}
        onClick={onCloseClick}
      >
        {item.name}
      </Link>
    </li>
  );
};

export default MenuNavItem;
