import React from "react";

import ExpertiseDetails from "../data/expertise";
import { CustomLink } from "../atoms/";

const SingleExpertiseGrid = ({ index }) => {
  const expertiseIndex = index - 1;
  const nextExpertise = () => {
    let data = [];
    if (expertiseIndex > 14) {
      if (expertiseIndex === 15) {
        data.push(ExpertiseDetails[16], ExpertiseDetails[17], ExpertiseDetails[0]);
        return data;
      } else if (expertiseIndex === 16) {
        data.push(ExpertiseDetails[17], ExpertiseDetails[0], ExpertiseDetails[1]);
        return data;
      } else if (expertiseIndex === 17) {
        data.push(ExpertiseDetails[0], ExpertiseDetails[1], ExpertiseDetails[2]);
        return data;
      } else if (expertiseIndex === 18) {
        data.push(ExpertiseDetails[0], ExpertiseDetails[1], ExpertiseDetails[2]);
        return data;
      }
    } else {
      data = ExpertiseDetails.slice(expertiseIndex + 1, expertiseIndex + 4);
      return data;
    }
  };
  return (
    <div className="grid grid-cols-3">
      {nextExpertise().map((el, id) => {
        const singleExpertiseLink = `/expertise/${el.index}`;
        return (
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-easing="linear"
            data-aos-once="false"
            key={id}
            className="h-48 md:h-64 lg:h-80 bg-center bg-cover bg-no-repeat flex items-end"
            style={{
              backgroundImage: `linear-gradient(180deg, #00000000 0%, #000000 100%), url(${el.picture}) `,
            }}
          >
            <div className="px-2 md:px-10 pb-2 md:pb-12">
              <p className="font-sans text-white font-bold text-sm md:text-2xl mb-4">{el.title}</p>
              <CustomLink link={singleExpertiseLink} text="Learn More" className="text-white" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { SingleExpertiseGrid };
