import React, { useReducer } from "react";
import { Route, Switch } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import SubHeader from "../../templates/SubHeader";
import { About } from "./About";
import { IntellectualProperty } from "./IntellectualProperty";
import { Expertise } from "./Expertise";
import { Team } from "./Team";
import { SingleTeamMember } from "./SingleTeamMember";
import { SingleExpertise } from "./SingleExpertise";
import { Contact } from "./Contact";
import { Context, reducer } from "../../store/reducer";
import { Insights } from "./Insights";
import { Careers } from "./Careers";

const initialState = {
  background: "",
  headerTitle: "",
};

AOS.init();

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <div className="overflow-hidden">
      <Context.Provider value={{ state, dispatch: dispatch }}>
        <SubHeader />
        <Switch>
          <Route path="/about" exact component={About} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/team/:id" component={SingleTeamMember} />
          <Route path="/expertise" exact component={Expertise} />
          <Route exact path="/expertise/:id" component={SingleExpertise} />
          <Route path="/intellectual-property" exact component={IntellectualProperty} />
          <Route path="/contact-us" exact component={Contact} />
          <Route exact path="/insight" component={Insights} />
          <Route exact path="/career" component={Careers} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
      </Context.Provider>
    </div>
  );
};

export default Index;
