import React, { Fragment, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Context } from "../../store/reducer";
import SectionIntro from "../../templates/SectionIntro";
import { SectionWrapper } from "../../layout/SectionWrapper";
import ExpertiseDetails from "../../data/expertise";
import { SingleExpertiseGrid } from "../../organisms";
import { setState } from "../../store/actionsCreators";
import { Paragraph, HeaderText } from "../../atoms";

const SingleExpertise = () => {
  const { id } = useParams();
  const singleExpertiseDetails = ExpertiseDetails.find((el) => el.index === Number(id));

  const { dispatch } = useContext(Context);

  useEffect(() => {
    dispatch(setState(singleExpertiseDetails.picture, singleExpertiseDetails.title));
  }, [dispatch, singleExpertiseDetails]);

  let template;
  if (Number(id) === 19) {
    template = (
      <Fragment>
        {singleExpertiseDetails.text2}
        {singleExpertiseDetails.text3}
        {singleExpertiseDetails.text4}
      </Fragment>
    );
  } else {
    template = (
      <Fragment>
        {singleExpertiseDetails.text2 && (
          <Paragraph className="mt-3" text={singleExpertiseDetails.text2} />
        )}
        {singleExpertiseDetails.text3 && (
          <Paragraph className="mt-3" text={singleExpertiseDetails.text3} />
        )}
        {singleExpertiseDetails.text4 && (
          <Paragraph className="mt-3" text={singleExpertiseDetails.text4} />
        )}
      </Fragment>
    );
  }

  return (
    <>
      <div className="bg-white">
        <SectionWrapper>
          <SectionIntro title={singleExpertiseDetails.title} />
          <HeaderText text={singleExpertiseDetails.header} />
          <div className="mb-20">
            <Paragraph text={singleExpertiseDetails.text1} />
            {template}
          </div>
          <SectionIntro title="Other areas of expertise" />
        </SectionWrapper>
        <SingleExpertiseGrid index={Number(singleExpertiseDetails.index)} />
      </div>
    </>
  );
};

export { SingleExpertise };
