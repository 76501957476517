import React from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";

import { headerItems } from "../../data/headerItems";
import "./Menu.css";
import MenuNavItem from "../../atoms/MenuNavItem";

const Menu = ({ open, onCloseClick }) => {
  const location = useLocation();
  return (
    <div className={classnames({ open: open })}>
      <div className="navigation">
        <div className="menu">
          <ul className="lg:flex md:w-auto lg:w-full xl:w-full first-list">
            {headerItems.map((item, index) =>
              index < 3 ? (
                <MenuNavItem
                  onCloseClick={onCloseClick}
                  key={index}
                  isActive={
                    location.pathname === item.url ||
                    item.subItems?.find((sub) => sub.url === location.pathname)
                  }
                  item={item}
                />
              ) : null
            )}
          </ul>
          <ul className="lg:flex xl:mt-14 md:mt-10 md:w-auto lg:w-full xl:w-4/5 second-list">
            {headerItems.map((item, index) =>
              index > 2 && index < 6 ? (
                <MenuNavItem
                  onCloseClick={onCloseClick}
                  key={index}
                  isActive={
                    location.pathname === item.url ||
                    item.subItems?.find((sub) => sub.url === location.pathname)
                  }
                  item={item}
                />
              ) : null
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Menu;
