import classnames from "classnames";
import { CustomLink } from "../atoms";

const ExpertiseCard = ({ image, title, span, id }) => {
  return (
    <div
      data-aos="slide-up"
      data-aos-duration="1000"
      data-aos-easing="linear"
      data-aos-once="true"
      className={classnames("relative text-white w-full h-56 md:h-auto shadow-md", {
        "md:row-span-3": span,
      })}
    >
      <img src={image} alt="title" className="w-full h-full" />
      <div className="absolute bottom-0.5 left-4 w-3/4">
        <p className="text-2xl xl:text-3xl lg:text-3xl md:text-2xl font-extrabold">{title}</p>
        <section className="mb-3 mt-2 md:mb-8 xl:mt-3 md:mt-2">
          <CustomLink text="learn more" link={`/expertise/${id}`} />
        </section>
      </div>
    </div>
  );
};

export default ExpertiseCard;
