import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Header, Footer } from "./organisms";
import Home from "./pages/Home.jsx";
import SubHeading from "./pages/SubHeading/Index";
import ScrollToTop from "./templates/ScrollToTop";

function App() {
	return (
		<Router>
			<Header />
			<ScrollToTop>
				<Switch>
					<Route path="/" exact component={Home} />
					<Route path="/" component={SubHeading} />
				</Switch>
			</ScrollToTop>
			<Footer />
		</Router>
	);
}

export default App;
