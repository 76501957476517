import saratu from "../assets/people/new/saratunew.png";
import saratu2 from "../assets/people/new/saratuwhite.png";
import yetunde from "../assets/people/Yetunde.png";
import yetunde2 from "../assets/people/new/yetunde.png";
import danlami from "../assets/people/Danlami.png";
import danlami2 from "../assets/people/new/danlami.jpeg";
import adebola from "../assets/people/Adebola.png";
import adebola2 from "../assets/people/new/adebola.jpeg";
import halima from "../assets/people/new/halimanew.jpeg";
import gapsiso from "../assets/people/new/gapsiso.jpeg";
import gapsiso2 from "../assets/people/new/gapsisowhite.png";
import janet from "../assets/people/new/janet.jpeg";
import janet2 from "../assets/people/new/janetWhite.png";
import halima2 from "../assets/people/new/halimawhite.png";
import bulus from "../assets/people/Bulus.png";
import bulus2 from "../assets/people/new/bulus.jpeg";
import conrad from "../assets/people/conrad2.jpg";
import agboola from "../assets/people/agboola.jpg";
import josephine from "../assets/people/josephine.png";
import omolayo from "../assets/people/omolayo.jpg";

const TeamDetails = [
  {
    picture: bulus,
    picture2: bulus2,
    name: "Dr. BB Dan-Habu",
    designation: "Chief Counsel",
    index: 1,
    text1:
      "Dr. Dan-Habu is the Chief Counsel and Head of Practice of the Firm. He holds an LL.B (Hons.) Degree from the University of Maiduguri and an LL.M (Master of Laws) Degree from the University of Lagos. He was called to the Nigerian Bar, and has over 30 years post-call experience. He has attended various courses at home and abroad, including Harvard Law School, Boston; The Institute of Maritime Law, University of Southampton, UK; and Clark Atlanta University, USA.",
    text2:
      "He has diverse experience in both the public and private sector. He has worked as State Counsel in the Ministry of Justice, (1985 – 1986); Member, Ministerial Committee for the Review of Nigeria’s Mineral Laws and Regulations; and Member, Tribunal of Inquiry into the Civil Disturbances in Lagos State, (2000 – 2001). He participated in the Review and the Drafting of the Agreement between the Federal Government of Nigeria and Global Minerals on the Nigerian Minerals Appraisal Monetization Program (‘’NIMAPOP’’).",
    text3:
      "He seats on the Board of several local and foreign Companies. He is a registered Capital Market Solicitor and member of several professional bodies and Associations, including, the Nigerian Bar Association and International Bar Association. He is actively involved in litigation.",
  },
  {
    picture: halima,
    picture2: halima2,
    name: "Mrs. Halima Dan-Habu",
    designation: "Deputy Chief Counsel",
    index: 2,
    text1:
      "Mrs. Dan-Habu graduated from the University of Maiduguri with an LL.B Hons in 1986 and an LL.M Degree (1990) from the foremost University of Lagos, Nigeria. She attended the Nigerian Law School and was called to the Nigerian Bar in 1988.",
    text2:
      "She has worked with several frontline law firms and corporate organisations, among which is the United Bank for Africa, (UBA Plc) from 1994 to 2000, where she rose to the rank of a Manager before leaving them in year 2000 to join our Firm, where she is now the Deputy Chief Counsel.",
    text3:
      "Mrs. Dan-Habu has attended several courses within Nigeria and the United States and she has versatile experience in Corporate Law, specifically secured credit transactions and corporate finance/advisory services.",
  },
  {
    picture: gapsiso,
    picture2: gapsiso2,
    name: "Dennis Gapsiso",
    designation: "Senior Associate & Head of Chambers (Abuja)",
    index: 3,
    text1:
      "Mr. Gapsiso graduated with an LL.B from University of Maiduguri and an LL.M from University of Ibadan. He has attended several courses home and abroad including a course on Tax Law/Taxation from the Duke University School of Law, USA.",
    text2:
      "He is experienced in advising on Taxation, Insurance and Arbitration issues. And has worked as the honourable Commissioner of Tax Appeal Tribunal in the Lagos Zone from 2010 – 2016.",
    text3:
      "He is a registered Tax Practitioner at the Chartered Institute of Taxation of Nigeria and a member of the Nigerian Bar Association",
  },

  {
    picture: yetunde,
    picture2: yetunde2,
    name: "Adegoke Yetunde",
    designation: "Associate",
    index: 4,
    text1:
      "Ms. Adegoke is a Counsel in the Firm with Bachelor of Law and Master of Law (International Criminal Law and Security) degrees from the University of Lagos and University of Northampton respectively. She holds certification in Competition Law and Merger Fillings from the Legal Education Centre in South Africa, and a certificate in Legal Drafting from the Law Society of South Africa.",
    text2:
      "She attended the Nigerian Law School, Lagos and has versatile experience with national and international organisations, advising on Corporate, Employment, Labour and International Law.",
    text3:
      "She is a member of the Nigerian Bar Association and a Reviewer to the Contemporary Voices, The St Andrews Journal of International Relations.",
  },
  {
    picture: saratu,
    picture2: saratu2,
    name: "Dan-Habu Saratu",
    designation: "Associate",
    index: 6,
    text1:
      "Ms. Saratu graduated with an LL.B (hons.) from the University of Kent, Canterbury, and obtained an LL.M from Queen Mary University of London, Paris. In addition, she holds certifications from the University of Oxford, American Society of Appraisers (ASA) and Cornell University.",
    text2:
      "Saratu is experienced in legal matters relating to emerging and disruptive technology and specializes in all aspects of intellectual property law and practice, especially as it relates to searches, filings, registration, protection and enforcement. Saratu also has ample experience in cross border corporate and commercial agreements.",
    text3:
      "She is a member of Nigerian Bar Association, International Association for the Protection of Intellectual Property (AIPPI), International Trademark Association (INTA), Kent Critical Law Society (KCLS), Licensing Executive Society (LES) and Association of Trade Mark and Design Law Practitioners (APRAM).",
  },
  {
    picture: janet,
    picture2: janet2,
    name: "Janet Gapsiso",
    designation: "Associate",
    index: 7,
    text1:
      "Ms. Janet Gapsiso graduated with an LL.B (hons.) from Afe Babalola University Ado-Ekiti and was called to the Nigerian Bar in 2018.",
    text2:
      "She is experienced in advising on legal matters relating to Intellectual Property and Entertainment Law.",
    text3:
      "She is a member of the Nigerian Bar Association and the International Association for the Protection of Intellectual Property (AIPPI).",
  },
  {
    picture: danlami,
    picture2: danlami2,
    name: "Danlami Sopiya Boka",
    designation: "Senior Associate & Head of Litigation",
    index: 8,
    text1:
      "Mr Sopiya graduated with an LL.B from the University of Maiduguri and an LL.M from the University of Lagos. He holds a certificate in Drafting Documents for Litigation from the Law Society of South Africa, and a Certificate in Transnational Commercial Agreements, Litigation and Arbitration from University of Verona, Italy.",
    text2:
      "Danlami is experienced in various aspects of complex Litigation and Alternative Dispute Resolution and has worked on Commercial Agreements and various matters relating to Probate and Property Law.",
    text3:
      "He is an active member of the Nigerian Bar Association (NBA) and the Commonwealth Lawyers Association (CLA).",
  },
  {
    picture: adebola,
    picture2: adebola2,
    name: "Adebola Moyede Oluwadeyi",
    designation: "Senior Associate & Head of Chambers (Lagos)",
    index: 9,
    text1:
      "Mrs. Adebola Oluwadeyi holds a Bachelor’s Degree in Law from the famous University of Benin, Benin-City. She attended the Nigerian Law School and was called to the Nigerian Bar in 2001. She has worked with several international and domestic corporate organisations, advising on Mergers and Acquisitions, Immigration, Labour and Employment Law. She also has versatile experience in Company Law and Corporate Law, especially secured credit transactions, and corporate finance advisory.",
    text2:
      "Adebola has attended several courses home and abroad, including the Transnational Commercial Agreement, Litigation and Arbitration course at the University of Verona, Italy and Drafting Litigation Documents course organised by the Law Society of South Africa.",
    text3:
      "She is an active member of the Nigerian Bar Association (NBA), the Commonwealth Lawyers Association (CLA) and a registered Capital Market Solicitor.",
  },
];

const ParaLegals = [
  {
    picture: omolayo,
    picture2: omolayo,
    name: "Omolayo Olowoparija",
    index: 1,
  },
  {
    picture: conrad,
    picture2: conrad,
    name: "Conrad Egbuna",
    index: 2,
  },

  {
    picture: josephine,
    picture2: josephine,
    name: "Josephine Okafor",
    index: 3,
  },
  {
    picture: agboola,
    picture2: agboola,
    name: "Agboola Akinpelu",
    index: 4,
  },
];
export { TeamDetails, ParaLegals };
