import React, { useContext, useEffect } from "react";

import { SectionWrapper } from "../../layout/SectionWrapper";
import SectionIntro from "../../templates/SectionIntro";
import { HeaderText, Paragraph } from "../../atoms";
import { Context } from "../../store/reducer";
import { setState } from "../../store/actionsCreators";
import intellectualPropertyImage from "../../assets/intellectualProperty/intellectualBg.png";

const IntellectualProperty = () => {
  const { dispatch } = useContext(Context);

  useEffect(() => {
    dispatch(
      setState(
        intellectualPropertyImage,
        "Intellectual Property Finance & Valuation"
      )
    );
  }, [dispatch]);

  return (
    <div className="bg-blue-darkBlue">
      <SectionWrapper>
        <header>
          <SectionIntro title="Intellectual Property Finance & Valuation" />
          <HeaderText text="  Our Intellectual Property Finance consulting unit is comprised of lawyers," />
          <Paragraph
            text="economists, econometricians and accountants, each with practical
            experience of intellectual property and financial matters. We are
            dedicated to providing a broad range of professional services which
            are traditionally not offered by IP firms, ranging from valuation,
            exploitation and monetisation to the sale of intellectual property
            assets."
          />
        </header>
      </SectionWrapper>
    </div>
  );
};

export { IntellectualProperty };
