const Provide = () => {
	return (
		<div>
			<p className="text-xl md:text-2xl lg:text-3xl mt-12 mb-8 font-extrabold max-w-4xl md:max-w-5xl">
				We use sophisticated legal, financial, technological, and economic analyses to provide
				strategic, thoughtful and tailored advice on:
			</p>
		</div>
	);
};

export default Provide;
