import React from "react";
import { Link } from "react-router-dom";

const CustomLink = ({ text, link, className }) => {
  return (
    <Link
      data-aos="fade-in"
      data-aos-duration="500"
      data-aos-easing="linear"
      data-aos-once="true"
      to={link}
      className={`inline-block no-underline pb-2.5 lg:pb-3 xl:pb-3.5 border-b-4 uppercase border-blue-light tracking-wider text-xs md:text-sm xl:text-base font-serif transform transition duration-500 hover:scale-105 ${className}`}
    >
      {text}
    </Link>
  );
};

export { CustomLink };
