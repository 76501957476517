import React from "react";

const SectionIntro = ({ title, className }) => {
  return (
    <section
      data-aos="fade-down"
      data-aos-duration="1000"
      data-aos-easing="linear"
      data-aos-once="false"
      className={`flex items-center ${className ? className : "xl:mb-8 md:mb-6"}`}
    >
      <p
        className={`uppercase font-semibold tracking-widest text-sm lg:text-base xl:text-lg mr-2 md:mr-4 ${className}`}
      >
        {title}
      </p>
      <hr className="text-blue-light w-10 md:w-16 lg:w-24 xl:w-32" />
    </section>
  );
};

export default SectionIntro;
