import React from "react";
import ACQ5 from "../assets/about/ACQ5.png";
import Corporate from "../assets/about/Corporate.png";
import Global from "../assets/about/Global.png";

const Award = () => {
  return (
    <section className="bg-white lg:text-center">
      {/* <p className="uppercase font-medium tracking-widest xl:text-lg lg:text-base md:text-sm mr-4 mb-2">
        Awards/Honours
      </p>
      <hr className="text-blue-light w-28 rule" />
      <HeaderText text="B.B. Dan-Habu & Co Prizes" /> */}
      <div className="flex justify-center items-center">
        <img
          src={ACQ5}
          alt="ACQ5"
          className="mr-4 w-10 h-10 object-contain md:w-40 md:h-24"
        />
        <img
          src={Corporate}
          alt="Corporate"
          className="mr-4 w-10 h-10 object-contain md:w-40 md:h-24"
        />
        <img
          src={Global}
          alt="Global"
          className="mr-4 w-10 h-10 object-contain md:w-40 md:h-24"
        />
      </div>
    </section>
  );
};

export { Award };
