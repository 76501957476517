import aboutImage from "../assets/about/aboutHeaderBg@2x.png";
import bankingFinance from "../assets/bankingFinance/bankingFinanceBg@2x.png";
import environmentalImage from "../assets/environmental/environmentalBg@2x.png";
import internationalImage from "../assets/international/internationalBg@2x.png";
import shippingImage from "../assets/shipping/shippingBg@2x.png";
import capitalMarketImage from "../assets/capitalMarket/capitalMarket@2x.png";
import expatriateImage from "../assets/expatriate/expatriateBg@2x.png";
import labourImage from "../assets/labour/labourBg@2x.png";
import taxImage from "../assets/tax/taxBg@2x.png";
import constitutionImage from "../assets/constitutional/constitutionalBg@2x.png";
import litigationImage from "../assets/litigation/litigationBg@2x.png";
import mergersImage from "../assets/mergers/mergers@2x.png";
import willImage from "../assets/will/will@2x.png";
import corporateImage from "../assets/corporate/corporateBg@2x.png";
import generalPracticeImage from "../assets/generalPractice/generalPracticeBg@2x.png";
import realEstateImage from "../assets/realEstate/realEstate@2x.png";
import emergingImage from "../assets/emergingMarket/emergingMarketBg@2x.png";
import intellectualImage from "../assets/intellectual/intellectualBg@2x.png";
import restructuringImage from "../assets/restucturing/restructuringBg@2x.png";
import ourPeopleImage from "../assets/ourPeople/ourPeopleBg@2x.png";
import intellectualPropertyImage from "../assets/intellectualProperty/intellectualBg.png";

export const headerItems = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "About Us",
    backgroundImage: aboutImage,
    url: "/about",
  },
  {
    name: "Expertise",
    backgroundImage: "",
    url: "/expertise",
    subItems: [
      {
        name: "Banking Finance Law",
        backgroundImage: bankingFinance,
        url: "/expertise/1",
      },
      {
        name: "Environmental Law",
        backgroundImage: environmentalImage,
        url: "/expertise/2",
      },
      {
        name: "International Commercial Transactions",
        backgroundImage: internationalImage,
        url: "/expertise/3",
      },
      {
        name: "Shipping, Aviation & Transport Law",
        backgroundImage: shippingImage,
        url: "/expertise/3",
      },
      {
        name: "Capital Market",
        backgroundImage: capitalMarketImage,
        url: "/expertise/4",
      },
      {
        name: "Expatriate Quota & Residence Permit",
        backgroundImage: expatriateImage,
        url: "/expertise/5",
      },
      {
        name: "Labour, Employment & Pension Law",
        backgroundImage: labourImage,
        url: "/expertise/6",
      },
      {
        name: "Tax & Taxation",
        backgroundImage: taxImage,
        url: "/expertise/7",
      },
      {
        name: "Constitutional & Statutory Interpretation",
        backgroundImage: constitutionImage,
        url: "/expertise/8",
      },
      {
        name: "Litigation & Arbitration",
        backgroundImage: litigationImage,
        url: "/expertise/9",
      },
      {
        name: "Mergers & Acquisitions",
        backgroundImage: mergersImage,
        url: "/expertise/10",
      },
      {
        name: "Will, Trust & Probate",
        backgroundImage: willImage,
        url: "/expertise/11",
      },
      {
        name: "Corporate & Commercial Law",
        backgroundImage: corporateImage,
        url: "/expertise/12",
      },
      {
        name: "General Practice",
        backgroundImage: generalPracticeImage,
        url: "/expertise/13",
      },
      {
        name: "Real Estate",
        backgroundImage: realEstateImage,
        url: "/expertise/14",
      },
      {
        name: "Emerging Market",
        backgroundImage: emergingImage,
        url: "/expertise/15",
      },
      {
        name: "Intellectual Property Protection and Commercialization",
        backgroundImage: intellectualImage,
        url: "/expertise/16",
      },
      {
        name: "Restructuring & Insolvency",
        backgroundImage: restructuringImage,
        url: "/expertise/17",
      },
      {
        name: "Intellectual Property Finance & Valuation",
        backgroundImage: intellectualPropertyImage,
        url: "/expertise/18",
      },
    ],
  },
  {
    name: "Our People",
    backgroundImage: ourPeopleImage,
    url: "/team",
    subItems: [
      {
        name: "Dr. BB Dan-Habu",
        url: "/team/1",
      },
      {
        name: "Mrs. Halima Dan-Habu",
        url: "/team/2",
      },
      {
        name: "Adebola Moyede Oluwadeyi",
        url: "/team/3",
      },
      {
        name: "Danlami Sopiya Boka",
        url: "/team/4",
      },
      {
        name: "Adegoke Yetunde",
        url: "/team/5",
      },
      {
        name: "Dan-Habu Saratu",
        url: "/team/7",
      },
      {
        name: "Conrad Egbuna",
        url: "/team/8",
      },
      {
        name: "Dr. Bulus Bunken Dan-Habu",
        url: "/team/9",
      },
      {
        name: "Dr. Bulus Bunken Dan-Habu",
        url: "/team/10",
      },
      {
        name: "Dr. Bulus Bunken Dan-Habu",
        url: "/team/11",
      },
      {
        name: "Dr. Bulus Bunken Dan-Habu",
        url: "/team/12",
      },
      {
        name: "Dr. Bulus Bunken Dan-Habu",
        url: "/team/13",
      },
      {
        name: "Dr. Bulus Bunken Dan-Habu",
        url: "/team/14",
      },
    ],
  },
  {
    name: "Contact Us",
    url: "/contact",
  },
  {
    name: "Careers",
    url: "/career",
  },
];

export const extraItems = [
  {
    name: "Contact Us",
    url: "/contact",
  },
  {
    name: "Careers",
    url: "/career",
  },
];
