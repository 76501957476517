import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TeamDetails } from "../../data/team";
import { SingleTeamGrid } from "../../organisms";
import { SectionWrapper } from "../../layout/SectionWrapper";
import { Context } from "../../store/reducer";
import { setState } from "../../store/actionsCreators";
import SectionIntro from "../../templates/SectionIntro";
import { Paragraph } from "../../atoms";

const SingleTeamMember = () => {
  const { id } = useParams();
  const singleMemberDetails = TeamDetails.find((el) => el.index === Number(id));

  const { dispatch } = useContext(Context);

  useEffect(() => {
    dispatch(setState(singleMemberDetails.picture, singleMemberDetails.name));
  }, [dispatch, singleMemberDetails]);

  return (
    <>
      <div className="bg-white">
        <SectionWrapper className="mt-10">
          <SectionIntro title="Our Legal Team" />
          <div className="md:flex mt-5 md:mt-0 text-white xl:mb-16 md:mb-14">
            <div
              className="w-80 md:w-96 lg:w-80 h-team-img lg:mr-20 md:mr-8"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-easing="linear"
              data-aos-once="false"
            >
              <img
                className="object-cover h-full w-full"
                src={singleMemberDetails.picture}
                alt="name"
              />
            </div>
            <div className="mt-5 md:mt-0 md:w-team-text text-base font-light font-serif">
              <Paragraph text={singleMemberDetails.text1} className="text-blue-bg" />
              <Paragraph className="mt-3 text-blue-bg" text={singleMemberDetails.text2} />
              <Paragraph className="mt-3 text-blue-bg" text={singleMemberDetails.text3} />
            </div>
          </div>
        </SectionWrapper>
        <SectionWrapper>
          <SectionIntro title="Other team members" />
        </SectionWrapper>
        <SingleTeamGrid index={Number(singleMemberDetails.index)} />
      </div>
    </>
  );
};

export { SingleTeamMember };
