import React from "react";

const HeaderText = ({ text, className, style }) => {
  return (
    <p
      data-aos="fade-down"
      data-aos-duration="1000"
      data-aos-easing="linear"
      data-aos-once="false"
      className={`text-2xl lg:text-4xl 2xl:text-5xl font-bold lg:font-black font-serif max-w-5xl md:max-w-6xl mt-8 mb-10 md:mb-4 lg:mb-6 xl:mb-8 ${className}`}
      style={style}
    >
      {text}
    </p>
  );
};

export { HeaderText };
