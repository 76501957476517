const Paragraph = ({ text, className }) => {
  return (
    <p
      data-aos="fade-down"
      data-aos-duration="500"
      data-aos-easing="linear"
      data-aos-once="true"
      className={`text-gray-DEFAULT opacity-60 tracking-wide xl:text-base lg:text-sm text-sm font-light font-serif ${className}`}
    >
      {text}
    </p>
  );
};

export { Paragraph };
