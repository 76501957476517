import React, { useContext, useEffect, useState } from "react";

import image from "../../assets/icons/loupe.svg";
import { SectionWrapper } from "../../layout/SectionWrapper";
import { Context } from "../../store/reducer";
import { BlogCard } from "../../organisms";
import { BlogData } from "../../data/blogData";
import { setState } from "../../store/actionsCreators";
import newsImage from "../../assets/news/newsBg.png";

const Insights = () => {
  const [searchData, setSearchData] = useState("");
  const { dispatch } = useContext(Context);

  useEffect(() => {
    dispatch(setState(newsImage, "Insight"));
  }, [dispatch]);

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchData(() => value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchData.length > 0) {
      console.log(searchData);
      setSearchData("");
    }
    return;
  };
  return (
    <div className="pt-10 pb-36">
      <SectionWrapper>
        <form onSubmit={handleSearch}>
          <div
            data-aos="slide-down"
            data-aos-duration="1500"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
            style={{
              border: "1px solid #B9B9B9",
              display: "flex",
              backgroundColor: "#F8FCFF",
            }}
            className="pl-7 py-5"
          >
            <input
              type="text"
              placeholder="Search"
              onChange={handleChange}
              value={searchData}
              className="w-11/12 h-11 focus:outline-none"
              style={{
                borderRight: "1px solid #E1E8F3",
                backgroundColor: "#F8FCFF",
              }}
            />
            <button
              type="submit"
              style={{ backgroundColor: "#F8FCFF" }}
              className="w-1/12 focus:outline-none"
            >
              <img className="w-3.5 mx-auto" src={image} alt="search" />
            </button>
          </div>
        </form>
        <div className="mt-5 lg:grid lg:grid-cols-3 gap-4">
          {BlogData?.length > 0 ? (
            BlogData?.map((el, id) => {
              return (
                <BlogCard
                  image={el.picture}
                  title={el.title}
                  date={el.date}
                  index={el.index}
                  key={id}
                  span={el.span}
                />
              );
            })
          ) : (
            <p className="text-gray">No news available</p>
          )}
        </div>
      </SectionWrapper>
    </div>
  );
};

export { Insights };
