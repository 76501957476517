import React, { useContext } from "react";

import { Context } from "../store/reducer";

const SubHeader = () => {
  const { state } = useContext(Context);

  return (
    <div
      className="flex flex-col"
      // style={{ backgroundImage: `url(${state?.background})` }}
      // justify-end bg-local bg-cover
    >
      <div className="container w-10/12 pb-6">
        <h2 className="lg:text-4xl md:text-3xl text-2xl text-white font-bold">
          {state.headerTitle}
        </h2>
      </div>
    </div>
  );
};

export default SubHeader;
