import { useEffect, useContext } from "react";
import SectionIntro from "../../templates/SectionIntro";
import { SectionWrapper } from "../../layout/SectionWrapper";
import { Context } from "../../store/reducer";
import expertiseImage from "../../assets/expertise/expertiseBg.png";

import { setState } from "../../store/actionsCreators";

const Careers = () => {
  const { dispatch } = useContext(Context);

  useEffect(() => {
    dispatch(setState(expertiseImage, "Careers"));
  }, [dispatch]);

  return (
    <div className="bg-white text-gray-light pt-20">
      <SectionWrapper>
        <div className="mb-10">
          <SectionIntro title="Careers" className="text-blue-darkBlue" />
        </div>
        <div className="lg:grid lg:grid-cols-12">
          <div
            className="col-start-1 col-end-6"
            data-aos="fade-right"
            data-aos-duration="1500"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <p className="text-blue-darkBlue text-xl  font-medium">
              If you believe you have the ability to strengthen our existing team and you find the
              prospect of <br /> joining our team exciting,
            </p>
            <p className="mt-12 font-bold text-2xl text-blue-darkBlue">please send an email to</p>
            <p className="text-blue-lightBlue text-2xl font-bold mb-8">info@bbdanhabuco.com</p>
          </div>
          <div
            className="col-start-8 col-end-13"
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <img src={expertiseImage} alt="expertiseImage" className="rounded-lg w-full h-full" />
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};

export { Careers };
