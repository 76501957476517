import banking from "../assets/expertise/banking.png";
import capital from "../assets/expertise/capital.png";
import corporate from "../assets/expertise/corporate.png";
import environmental from "../assets/expertise/environmental.png";
import estate from "../assets/expertise/estate.png";
import insolvency from "../assets/expertise/insolvency.png";
import intellectual from "../assets/expertise/intellectual.png";
import mergers from "../assets/expertise/mergers.png";
import permit from "../assets/expertise/permit.png";
import shipping from "../assets/expertise/shipping.png";
import tax from "../assets/expertise/tax.png";
import transaction from "../assets/expertise/transactions.png";
import labour from "../assets/expertise/labour.png";
import litigation from "../assets/expertise/litigation.png";
import will from "../assets/expertise/will.png";
import market from "../assets/expertise/market.png";
import intellectualPropertyImage from "../assets/intellectualProperty/intellectualBg.png";

const expertiseLandingItems = [
  {
    id: 1,
    title: "Banking Finance Law",
    image: banking,
    span: false,
  },
  {
    id: 3,
    title: "International Commercial Transactions",
    image: transaction,
    span: true,
  },
  {
    id: 2,
    title: "Environmental Law",
    image: environmental,
    span: true,
  },
  {
    id: 4,
    title: "Shipping,Aviation and Transport Law",
    image: shipping,
    span: false,
  },
  {
    id: 5,
    title: "Capital Market",
    image: capital,
    span: false,
  },
  {
    id: 6,
    title: "Expatriate Quota and Residence Permit",
    image: permit,
    span: false,
  },
  {
    id: 7,
    title: "Labour,Employment and Pension Law",
    image: labour,
    span: false,
  },
  {
    id: 8,
    title: "Tax & Taxation",
    image: tax,
    span: false,
  },
  {
    id: 9,
    title: "Litigation & Arbitration",
    image: litigation,
    span: true,
  },
  {
    id: 10,
    title: "Mergers and Acquisitions",
    image: mergers,
    span: true,
  },
  {
    id: 11,
    title: "Will,Trust $ Probate",
    image: will,
    span: false,
  },
  {
    id: 12,
    title: "Corporate & Commercial Law",
    image: corporate,
    span: false,
  },
  {
    id: 13,
    title: "Real Estate",
    image: estate,
    span: false,
  },
  {
    id: 14,
    title: "Emerging Market",
    image: market,
    span: false,
  },
  {
    id: 15,
    title: "Intellectual Property Protection and Commercialization",
    image: intellectual,
    span: true,
  },
  {
    id: 16,
    title: "Restructuring and Insolvency",
    image: insolvency,
    span: false,
  },
  {
    id: 17,
    title: "Intellectual Property Finance & Valuation",
    image: intellectualPropertyImage,
    span: false,
  },
];

export default expertiseLandingItems;
