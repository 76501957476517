import React, { useContext, useEffect } from "react";
import { ParalegalSlider, TeamSlider } from "../../organisms";

import { Context } from "../../store/reducer";
import { setState } from "../../store/actionsCreators";
import ourPeopleImage from "../../assets/ourPeople/ourPeopleBg.png";
import SectionIntro from "../../templates/SectionIntro";
import { HeaderText, Paragraph } from "../../atoms";

const Team = () => {
  const { dispatch } = useContext(Context);

  useEffect(() => {
    dispatch(setState(ourPeopleImage, "Our People"));
  }, [dispatch]);

  return (
    <>
      <div className="bg-blue-darkBlue">
        <div className="container pt-16 pb-10  m-auto w-10/12 lg:w-full xl:pt-28 lg:pt-20 lg:pb-10 md:pt-16 md:pb-8 relative">
          <SectionIntro title="Our People" className="text-white" />
          <div className="flex items-center text-blue-bg justify-between mb-1 lg:mb-11">
            <HeaderText className="font-bold md:text-2xl lg:text-5xl mt-5 text-white" text="Our Legal Team" />
          </div>
        </div>
        <TeamSlider />

        <div className="container pt-10 pb-10  m-auto w-10/12 lg:w-full lg:pt-28 lg:pb-20 md:pt-16 md:pb-8 relative">
          <div className="md:flex text-bg-blue-darkBlue">
            <div className="w-1/2">
              <p className="font-bold font-sans mb-4 md:mb-9 text-3xl text-white">Associates</p>
              <p className="font-serif text-sm text-white">Senator A.M Pepple</p>
            </div>
            <div className="w-1/2 mt-10 md:m-0 md:pl-5">
              <p className="font-bold font-sans mb-4 md:mb-9 text-3xl text-white">Consultants</p>
              <p className="font-serif text-sm text-white">
                Prof. O. Yerokun – Insurance/Corporate Affairs
              </p>
              <p className="font-serif text-sm mt-1 font-light text-white">
                Dr. Y.Y. Fisse – International Investment Law
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white pt-10 lg:w-full lg:pt-28 md:pt-16 relative">
          <div className="container m-auto w-10/12 lg:w-full">
            <SectionIntro title="Our People" />
            <div className="flex items-center text-blue-bg justify-between">
              <HeaderText text="Meet our experienced paralegal team." />
            </div>
            <Paragraph
              text="whose extensive knowledge and meticulous attention to details, support our lawyers in
            providing top-notch quality legal services. They work hand in hand with our Lawyers,
            Clients and Government Agencies to ensure excellent service delivery."
              className="mb-20 text-gray"
            ></Paragraph>
          </div>

          <ParalegalSlider />
          <div className="bg-blue-darkBlue px-10 md:py-16 lg:py-24 lg:px-36 text-white md:flex items-center">
            <p className="md:w-1/2 font-serif text-sm md:text-md mb-5 mt-7 md:m-0">
              If you believe you have the ability to strengthen our existing team and you find the
              prospect of joining our team exciting, please send an email to:
            </p>
            <a
              href="mailto:info@bbdanhabuco.com"
              className="text-2xl md:text-3xl underline font-sans font-bold md:ml-8"
            >
              info@bbdanhabuco.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export { Team };
