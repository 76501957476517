import React from "react";

import { CustomLink } from "../atoms";
import { TeamDetails } from "../data/team";

const SingleTeamGrid = ({ index }) => {
  const memberIndex = index - 1;
  const nextTeamMembers = () => {
    console.log(TeamDetails.length);
    let data = [];
    if (memberIndex > TeamDetails.length - 4) {
      if (memberIndex === TeamDetails.length - 3) {
        data.push(
          TeamDetails[TeamDetails.length - 2],
          TeamDetails[TeamDetails.length - 1],
          TeamDetails[0]
        );
        return data;
      } else if (memberIndex === TeamDetails.length - 2) {
        data.push(TeamDetails[TeamDetails.length - 1], TeamDetails[0], TeamDetails[1]);
        return data;
      } else if (memberIndex === TeamDetails.length - 1) {
        data.push(TeamDetails[0], TeamDetails[1], TeamDetails[2]);
        return data;
      }
    } else {
      data = TeamDetails.slice(memberIndex + 1, memberIndex + 4);
      return data;
    }
  };
  return (
    <div className="grid grid-cols-3">
      {nextTeamMembers().map((el, id) => {
        const singleMemberLink = `/team/${el.index}`;
        return (
          <div
            className="h-48 md:h-64 lg:h-80 bg-center bg-cover bg-no-repeat flex items-end"
            key={id}
            style={{
              backgroundImage: `linear-gradient(180deg, #00000000 0%, #000000 100%), url(${el.picture})`,
            }}
          >
            <div className="p-3 md:px-10 md:pb-12">
              <p className="font-sans text-white font-bold md:text-2xl text-sm">{el.name}</p>
              <p className="font-serif text-white mt-2 mb-6 md:text-sm small-text">
                {el.designation}
              </p>
              <CustomLink link={singleMemberLink} text="View profile" className="text-white" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { SingleTeamGrid };
