import React, { useContext, useEffect } from "react";
import { SectionWrapper } from "../../layout/SectionWrapper";
import SectionIntro from "../../templates/SectionIntro";

import { Context } from "../../store/reducer";
import { setState } from "../../store/actionsCreators";
import contactUsImage from "../../assets/contact-us/contactUs.png";
import { HeaderText } from "../../atoms";

const Contact = () => {
  const { dispatch } = useContext(Context);

  useEffect(() => {
    dispatch(setState(contactUsImage, "Contact Us"));
  }, [dispatch]);

  return (
    <div className="bg-white text-gray-light pt-10">
      <SectionWrapper>
        <div>
          <SectionIntro title="Contact Us" className="text-blue-darkBlue" />
          <HeaderText
            text="Get in touch with us today"
            className="text-blue-darkBlue"
          />
        </div>
        <section className="lg:flex pb-12 mt-20">
          <div
            data-aos="fade-right"
            data-aos-duration="1500"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
            className="rounded-lg w-full lg:w-2/3 h-96 mr-8 mb-12 md:mb-0 lg:mb-0"
          >
            <iframe
              className="w-full h-full"
              title="Office Location"
              scrolling="no"
              id="gmap_canvas"
              src="https://maps.google.com/maps?width=515&amp;height=371&amp;hl=en&amp;q=276,%20Murtala%20Muhammed%20Way,%20Yaba%20Lagos+(BB%20Dan-Habu)&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
            <script
              type="text/javascript"
              src="https://embedmaps.com/google-maps-authorization/script.js?id=6c2fb780d4413f315828ee334b73c87e30e70792"
            ></script>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
            className="p-8 rounded-lg w-full lg:w-60 bg-blue-lightBlue"
          >
            <p className="text-xs mb-4">Enquiries</p>
            <p className="text-sm mb-2 font-bold">01-5820574 | 01-582057</p>
            <p className="text-xs mb-4 mt-4">Email</p>

            <a
              href="mailto:info@bbdanhabuco.com"
              data-hover="info@bbdanhabuco.com"
              className="no-underline text-sm pb-4 mb-2 font-bold"
            >
              info@bbdanhabuco.com
            </a>
            <br />
            <p className="text-xs mt-2 mb-4">Address</p>
            <p className="text-sm pb-4 font-bold">
              276 Murtala Muhammed way, Yaba
            </p>
          </div>
        </section>
      </SectionWrapper>
    </div>
  );
};

export { Contact };
