import { Link } from "react-router-dom";
import classnames from "classnames";

const BlogCard = ({ image, title, date, index, id }) => {
  const span = (index + 1) % 4 === 0 ? true : false;

  return (
    <div
      data-aos="slide-up"
      data-aos-duration="1800"
      data-aos-easing="ease-in-out"
      data-aos-once="true"
      className={classnames("w-full", { "col-span-2": span })}
    >
      <Link>
        <div
          className="w-full bg-local bg-cover bg-no-repeat bg-center mb-4 md:mb-0"
          style={{
            height: "270px",
            maxHeight: "270px",
            padding: "30px 60px 0 32px",
            backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,0.6)),url(${image})`,
          }}
        >
          <p className="text-white mb-2" style={{ fontSize: "15px", letterSpacing: "3px" }}>
            INSIGHTS
          </p>
          <p className="font-serif text-white text-base mb-3.5" style={{ lineHeight: "26px" }}>
            {title}
          </p>
          <p className="font-serif text-white text-xs">{date}</p>
        </div>
      </Link>
    </div>
  );
};

export { BlogCard };
