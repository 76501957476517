import React, { Fragment, useState } from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import logo from "../assets/logo/danhabu-logo-retina3@2x.png";
import { ReactComponent as Hamburger } from "../assets/hamburger.svg";
import { ReactComponent as Close } from "../assets/close.svg";
import Menu from "./Menu/Menu";
import { headerItems } from "../data/headerItems";
import NavItem from "../atoms/NavItem";

const Header = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const onButtonClick = () => {
    setOpen((open) => !open);
  };

  const onCloseClickHandler = () => {
    setOpen(false);
  };

  return (
    <div className="absolute w-full z-20 overflow-hidden ">
      <div
        className={classnames("max-w-8xl mx-auto", {
          "md:py-1 lg:py-2 xl:py-3": open,
        })}
        style={{ width: "95%" }}
      >
        <Menu open={open} onCloseClick={onCloseClickHandler} />
        <header className="flex flex-col justify-between transition transform duration-700 ease-in-out pt-4 md:pt-0 fixed top-0 inset-x-0 z-50 h-16 bg-blue-dark px-10 mb-12">
          <div className="flex">
            <Link to="/">
            <img
              className="w-36 md:w-48 lg:w-52 xl:w-60 mr-6 md:mr-52"
              src={logo}
              alt="BB Dan Law"
            />
               </Link>
            <nav className="w-full mt-4">
              <ul className="hidden list-none md:flex">
                {headerItems.map((item, index) => (
                  <NavItem
                    key={index}
                    isActive={
                      location.pathname === item.url ||
                      item.subItems?.find(
                        (sub) => sub.url === location.pathname
                      )
                    }
                    item={item}
                  />
                ))}
              </ul>
            </nav>
            <button
              onClick={onButtonClick}
              className="focus:outline-none md:hidden h-2 md:h-6 lg:h-6 xl:h-8 w-20 md:w-20 lg:w-24 hover:bg-grey text-white font-bold text-sm md:text-sm lg:text-base xl:text-lg inline-flex items-center justify-between self-center rounded-none"
            >
              {open ? (
                <Fragment>
                  <span className="mr-1.5 md:mr-2">Close</span>
                  <Close className="w-6 h-6 md:w-10 md:h-10" />
                </Fragment>
              ) : (
                <Fragment>
                  <span className="mr-1.5 md:mr-2">Menu</span>
                  <Hamburger className="w-2 h-2 md:w-6 md:h-6" />
                </Fragment>
              )}
            </button>
          </div>
        </header>
      </div>
    </div>
  );
};

export { Header };
