import banking from "../assets/bankingFinance/bankingFinanceBg.png";
import environmental from "../assets/environmental/ennvironmentalBg.png";
import international from "../assets/international/internationalBg.png";
import shipping from "../assets/shipping/shippingBg.png";
import capital from "../assets/capitalMarket/capitalMarket.png";
import expatriate from "../assets/expatriate/expatriateBg.png";
import labour from "../assets/labour/labourBg.png";
import tax from "../assets/tax/taxBg.png";
import litigation from "../assets/litigation/litigationBg.png";
import mergers from "../assets/mergers/mergers.png";
import will from "../assets/will/will.png";
import corporate from "../assets/corporate/corporateBg.png";
import real from "../assets/realEstate/realEstate.png";
import emerging from "../assets/emergingMarket/emergingMarketBg.png";
import intellectual from "../assets/intellectual/intellectualBg.png";
import restructuring from "../assets/restructuring/restructuring.png";
import intellectualPropertyImage from "../assets/intellectualProperty/intellectualBg.png";
import Cards from "../templates/Cards";
import Advice from "../templates/Advice";
import Provide from "../templates/Provide";

const ExpertiseDetails = [
  {
    title: "Banking Finance Law",
    header:
      "Our banking and finance team provides comprehensive advisory and transactional services to a broad range of clients,",
    text1:
      "including onshore and offshore lenders (private and retail banks, financial institutions and mezzanine finance houses), as well as advising borrowers (corporates, funds, family offices and high net worth individuals).",
    text2:
      "We understand the regulatory environment in which our clients operate and the diverse range of financing structures available and offer clear, pragmatic advice and solutions to complex problems. We represent lenders, ensuring that their security and other requirements are satisfied, and support borrowers through the potentially daunting task of negotiating the lengthy documentation that has become market standard in this area.",
    text3:
      "Our experts work with leading finance providers, insurers, asset managers and many other financial market participants on capital and liquidity management, changing regulation, cost reduction and risk management. We act for new entrants to the financial services market, helping them to establish their new businesses and ensure regulatory compliance. We also represent clients facing regulatory investigations or similar challenges.",
    text4:
      "Our lawyers in the team have extensive experience, working with clients including both domestic and foreign banks, investment firms and other companies.",
    index: 1,
    picture: banking,
  },
  {
    title: "Environmental Law",
    header:
      "We handle a wide range of environmental law issues from advising on: the identification and management of contaminated land,",
    text1:
      "claims in relation to contaminated land, environmental insurance, compliance and permitting, environmental management procedures, auditing and privilege, planning, noise abatement and emissions trading.",
    text2:
      "This team is commercially focused environmental and regulatory law experts across the world advising on all aspects of environmental, product and trade law and liability. We closely track environmental policy, climate change and trade policy and regulatory developments and are in regular contact with policy makers and regulators whose work impacts our clients’ interests.",
    text3: "",
    text4: "",
    index: 2,
    picture: environmental,
  },
  {
    title: "International Commercial Transactions",
    header:
      "At our law firm, we provide trusted legal expertise to businesses operating in the global marketplace.",
    text1:
      " Our team of experienced lawyers are dedicated to helping our clients navigate the complex landscape of international business transactions with confidence and success. Our team's extensive knowledge and experience cover a wide range of international commercial transactions, including mergers and acquisitions, joint ventures, distribution agreements, licensing arrangements, and international trade. We have the expertise to handle transactions of any size or complexity. We work closely with our clients to understand their unique needs and develop strategies that maximize their chances of success.",
    text2:
      "With a deep understanding of international business laws and practices, our lawyers have successfully represented clients across various industries, enabling them to confidently handle complex cross-border transactions. We bring a global perspective to our work, ensuring that our clients' interests are protected in every jurisdiction.",
    text3: "",
    text4: "",
    index: 3,
    picture: international,
  },
  {
    title: "Shipping, Aviation & Transport Law",
    header: "Our Shipping and International Trade Team provides specialist advice for ship owners,",
    text1:
      "charterers, P&I and Defence Clubs, international traders, freight forwarders, marine insurers, brokers and inspection companies.",
    text2:
      "Our team has wide experience of acting in complex, high value multi-jurisdictional and cross-border disputes for UK and international clients in all major jurisdictions. We are familiar with leading arbitral institutions and procedures and have a strong ethos of achieving commercially sound outcomes and ensuring minimum disruption to your business.",
    text3:
      "We can provide expert advice on dispute resolution relating to: Bills of lading and cargo claims, charter parties including Time charter and Voyage charter issues, contracts of affreightment, international sales contracts, marine insurance, cruise and ferry line operation, passenger, crew and stevedore claims, P&I Club Rules, ship arrest and ship building.",
    text4: "",
    index: 4,
    picture: shipping,
  },
  {
    title: "Capital Market",
    header:
      "Our lawyers are registered with the Securities and Exchange Commission of Nigeria as Capital Market Consultants.",
    text1:
      "They are specialized in the whole spectrum of capital markets work including debt and equity transactions, structured debt and trusts and likewise work on benchmark and innovative transactions. We advise major market participants, including leading financial institutions, corporate bodies, regulators, government agencies and supranational organizations, as well as first time issuers and other new players in the capital markets.",
    text2:
      "We advise on Bond issues, debts, rights issues, securities, equity derivatives, initial public offerings, placings, hedging and stabilization of equity and equity-related products.",
    text3: "",
    text4: "",
    index: 5,
    picture: capital,
  },
  {
    title: "Expatriate Quota and Residence Permit",
    header:
      "Our Immigration Practice covers all aspects of inbound and outbound corporate immigration.",
    text1:
      "We understand that the challenges faced by employers and expatriates are owed largely to a lack of understanding of immigration processes in Nigeria. We have therefore positioned ourselves to advise and guide our Clients through these processes.",
    text2:
      "Our Immigration Practice is made up of proficient lawyers and paralegals with in-depth knowledge of the Immigration regime in Nigeria and a wealth of experience in immigration matters. Our services include: Procurement of operational licenses and permits, procurement of Expatriate Quota Approvals, work/Residence Permit, ECOWAS Permits, consular services, dependent applications and general advisory. We have built longstanding working relationships with the Nigerian Immigration Services (NIS), Nigerian Investment and Promotion Commission (NIPC) and the Ministry of Internal Affairs (MIA) and are proficiently experienced in labour and employment issues.",
    text3: "",
    text4: "",
    index: 6,
    picture: expatriate,
  },
  {
    title: "Labour, Employment and Pension Law",
    header:
      "A significant part of our work involves advising employers and employees on how to manage and resolve problems.",
    text1:
      "Our clients are drawn from a variety of backgrounds and sectors, including financial services, property, retail, hotel and leisure, academia, technology and agriculture. Clients include employers of all sizes, from global corporations to small businesses, and we pride ourselves on being able to appreciate and cater to the needs of each of them.",
    text2:
      " Our employment team helps clients to manage their employment issues with confidence. We cover all aspects of employment law, providing commercially focused and cost-effective advice. We also co-ordinate advice from lawyers.",
    text3: "",
    text4: "",
    index: 7,
    picture: labour,
  },
  {
    title: "Tax & Taxation",
    header:
      "We advise high net worth individuals, owner-managed businesses and large corporates on tax disputes and tax-related commercial litigation, including claims against professional advisors in relation to negligent tax advice.",
    text1:
      "Our advice centres around tax issues in relation to property investment, development, finance and funds work, we specialise in providing clear and strategic advice concerning direct and indirect taxes, including Stamp Duty Land Tax, VAT and business rates. Our clients include institutional and private investors, developers, entrepreneurs; both in the Nigeria and internationally goes beyond legal analysis to address the wider practical, commercial and strategic issues, ensuring our clients achieve the best possible outcome.",
    text2:
      "Our tax law team guides corporate and private clients through the Nigerian tax system, working proactively to manage their liabilities, preserve their reputations and support their commercial objectives. We use our strong relationships with specialist tax advisers in other jurisdictions to provide a fully integrated service for international clients and advise on transactions with an international dimension.",
    text3: "",
    text4: "",
    index: 8,
    picture: tax,
  },
  {
    title: "Litigation & Arbitration",
    header:
      "We provide services in the resolution of public and private disputes in civil matters through negotiated settlement or through the courts.",
    text1:
      "We bring together a team of experienced lawyers who are aware of current legislations and practices and are confident to provide services to clients.",
    text2:
      "We represent companies, partnerships, sole traders, private individuals and other organisations in civil litigation disputes. We have also defended our clients in cases instituted by international institutions, government agencies, local authorities, educational institutions and companies.",
    text3:
      "The firm has extensive skill and experience in handling multifaceted and complex disputes in courts of all levels including contract disputes, property litigation, debt claims, professional negligence, personal injury claims and judicial review. Where appropriate for a successful outcome, they encourage and offer various Alternative Dispute Resolution (ADR) solutions such as Negotiation, Mediation, Conciliation and Arbitration.",
    text4: "",
    index: 9,
    picture: litigation,
  },
  {
    title: "Mergers and Acquisitions",
    header:
      "Our corporate team has years of experience helping private and listed companies and a variety of other parties with their M&A needs.",
    text1:
      "Our team represents buyers, sellers, investors and lenders across the full spectrum of corporate transactions. Working closely with colleagues in our tax, finance, commercial and employment teams, we deliver legal solutions that help our clients reach their business objectives.",
    text2:
      "We pride ourselves on being responsive and delivering concise, practical advice which is focused on problem solving and getting the job done (assuming that is in our client’s interest). With a strong international dimension we can advise on complex multi-jurisdictional transactions, instructing and co- ordinating law firms and other professionals in different jurisdictions so that our clients enjoy a seamless service.",
    text3: "",
    text4: "",
    index: 10,
    picture: mergers,
  },
  {
    title: "Will, Trust & Probate",
    header:
      "The members of our team have a wealth of experience advising on trust and probate disputes.",
    text1:
      "The ambit of what we do includes: Will drafting, claims under the Inheritance, challenges to the validity of wills, applications for rectification of a will and advising trustees, beneficiaries in relation to trust assets, advice to trustees and beneficiaries and trust administration and proprietary estoppel and constructive trust claims",
    text2:
      "We advise on lifetime gifts, estate plans and taxation issues relating to inter-generational transfer strategies. Our team further gives specialist tax and succession planning advice to individuals, families, business people and landowners. We are able to assist with a wide range of clients’ circumstances including straightforward or complex family relationships, small or complex estates involving privately owned businesses, agricultural or farming interests and large property portfolios. We help clients structure their wealth planning so as to protect their heirs against divorce or creditor claims and to achieve tax efficiency.",
    text3: "",
    text4: "",
    index: 11,
    picture: will,
  },
  {
    title: "Corporate & Commercial Law",
    header:
      "At BB Dan-Habu & Co, we understand business and how legal solutions support commercial success.",
    text1:
      "We specialise in providing practical advice on the legal aspects of commercial agreements and operational matters to clients across a wide range of industry sectors. Drawing on the diverse specialist expertise of lawyers throughout our firm, including many with in-house counsel experience, we focus on identifying and implementing pragmatic, joined-up solutions that reflect our clients’ priorities and help them to achieve their goals.",
    text2:
      "Our lawyers have unrivalled experience in putting together innovative costs saving commercial arrangements. We advise clients on public and private M&A, disposals, demergers, joint ventures, restructurings, corporate governance and regulatory compliance, with focus on complex, cross-border transactions. Our integrated team includes experts in antitrust/competition law, intellectual property law, funds and investment management, insurance, private equity and public policy.",
    text3:
      "We work in partnership with you, tailoring the level of support to your business. Good service is at the heart of what we do, as is ensuring we have a thorough understanding of your organisation and its objectives. It is through this understanding and service promise that we have managed to grow long term working relationships with such a broad and diverse client base.",
    text4: "",
    index: 12,
    picture: corporate,
  },
  {
    title: "Real Estate",
    header:
      "From private individuals to public companies, we advise on a wide variety of Commercial Real Estate transactions.",
    text1:
      "Our team takes the time to understand your business and commercial needs in order to effectively resolve or prevent a wide range of property related disputes.",
    text2:
      "We act for a variety of clients including developers, entrepreneurs, high net worth individuals, celebrities, banks, investors, landlords and tenants across commercial property transactions, development projects and high value residential sales and purchases. The team is able to guide clients through the process and problem solve. We provide robust advice and take a proactive and creative approach to finding solutions when required.",
    text3: "",
    text4: "",
    index: 13,
    picture: real,
  },
  {
    title: "Emerging Market",
    header:
      "Many of our clients are seeking to maximise the opportunities presented by emerging economies",
    text1:
      "and are in search of an unrivalled and worldwide legal network that can deliver flexible and complete coverage in high growth markets and beyond. We understand the legal, political, commercial and regulatory environment distinctive to developing economies and we have experience in ensuring transactions are successfully concluded seamlessly.",
    text2: "",
    text3: "",
    text4: "",
    index: 14,
    picture: emerging,
  },
  {
    title: "Intellectual Property Protection and Commercialization",
    header:
      "We seek to manage and mitigate risk and to maximise the return from intellectual property (IP) assets for the benefit of our clients.",
    text1:
      "We advise on all aspects of the creation, exploitation and enforcement of IP rights and our lawyers have deep knowledge across a number of sectors, including: retail, technology, media, luxury goods, engineering and healthcare.",
    text2:
      "We handle disputes covering the full spectrum of intellectual property rights – ranging from dispute relating to patents, copyright, trademarks, passing off, industrial design rights and breach of confidence claims. We also provide strategic advice in managing and commercialising patent, trade mark portfolios and facilitate IP related agreements including trademark licensing, distribution agreements, sponsorship agreements and franchising deals.",
    text3: "",
    text4: "",
    index: 15,
    picture: intellectual,
  },
  {
    title: "Restructuring and Insolvency",
    header:
      "The team also provides advice on insolvency law and practice to a range of clients including insolvency practitioners,",
    text1:
      "corporate institutions banks, investors, creditors and a wide range of other stakeholders, and is well known for its direct, cost-effective and innovative approach to finding solutions.",
    text2:
      "We provide advice on the full range of restructuring and insolvency issues, including on administrations, liquidations, bankruptcies, provisional liquidations, restructuring, fraud and investigations, distressed asset acquisition, business litigation and international/cross border matters.",
    text3:
      "Our team acts for lenders, insolvency practitioners, creditors, directors and other stakeholders in circumstances where a company or individual is insolvent, experiencing financial difficulties or is the victim of a business-critical event such as fraud. The team is supported by experts in the fields of corporate, real estate, finance, employment, pensions and intellectual property – all of whom have experience of insolvency situations and managing financial difficulties.",
    text4: "",
    index: 16,
    picture: restructuring,
  },
  {
    title: "Intellectual Property Finance & Valuation",
    header: "Our Intellectual Property Finance consulting unit is comprised of lawyers,",
    text1:
      "economists, econometricians and accountants, each with practical experience of intellectual property and financial matters. We are dedicated to providing a broad range of professional services which are traditionally not offered by IP firms, ranging from valuation, exploitation and monetisation to the sale of intellectual property assets.",
    text2: <Provide />,
    text3: <Cards />,
    text4: <Advice />,
    index: 17,
    picture: intellectualPropertyImage,
  },
];

export default ExpertiseDetails;
